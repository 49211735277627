import React, { useState, useEffect } from "react";
import { useHistory, useParams, withRouter } from "react-router-dom";
import Segment from "../../../../components/Segment";
import {
  Button,
  Group,
  TextArea,
  Dropdown,
  DateSelect,
  Input,
  Toggle,
} from "../../../../components/Forms";
import API from "../../../../api";
import CommonFunctions from "../../../../CommonFunctions";
import Loader from "../../../../components/Loader";
import { CheckIcon, PlayIcon, StopIcon, XIcon } from "@heroicons/react/solid";
import MatterBreadcrumbs from "../../_components/MatterBreadcrumbs";

import ChainedContext from "../../../../contexts/chainedContext";
import AuthenticationReceiver from "../../../../plugins/clio_auth/_components/authenticationReceiver";

function Edit({auth}) {
  const history = useHistory();
  const params = useParams();

  const [userList, setUserList] = useState([]);
  const [matter, setMatter] = useState(null);
  const [data, setData] = useState({
    description: "",
    assigned: "",
    due_date: "",
    rateDetails: "",
    currentRate: 0,
    time: 0,
    end_time: null,
    isBillable: false
  });

  const [defaultAdminRate, setDefaultAdminRate] = useState(0);
  const [matterDebtors, setMatterDebtors] = useState([]);

  const [mountLoading, setMountLoading] = useState(true);

  const [timerClock, setTimerClock] = useState(0);
  const [timer, setTimer] = useState(true);

  /*useEffect(() => {
    if (mountLoading) {
      Promise.all([
        API.users.getAll().catch((e) => CommonFunctions.handleError(e)),
        API.matters.tasks
          .get(params.matter_id, params.task_id)
          .catch((e) => CommonFunctions.handleError(e)),
      ])
        .then((res) => {

          if (res[1]) {
            setData(res[1]);
          }
        })
        .finally(() => setMountLoading(false));
    }
  }, [params, mountLoading]); */

  useEffect(() => {
    if (mountLoading) {
      API.matters.get(params.matter_id).then((res) => {

        let scale = res.scale ? res.scale : "";
        let fee_thresholds = res.fee_thresholds ? res.fee_thresholds : [];
        setMatter(res);

        // API.matters.get(params.matter_id).then((tsk) => {

          API.matters.debtors
            .getAll(params.matter_id)
            .then((res2) => {
              setMatterDebtors(res2);

              API.users
                .getAll()
                .then((res3) => {

                  let assigned = null;
                  let assigned_rate = 0;

                  let rateDetails = "Using User Settings Rate";

                  setUserList(
                    res3.users.map((a) => {
                      let ft = fee_thresholds.filter(x => x.user == a._clioId);

                      let rate = a.rate;
                      
                      if(scale === "hourlyScale" && ft && ft[0]){
                        rate = parseFloat(ft[0].fee);
                      }

                      if(auth && a.email == auth.user.email){
                        assigned = a._id;
                        assigned_rate = rate; 

                        if(scale === "hourlyScale" && ft && ft[0]){
                          rateDetails = "Using Matter Hourly Fee Rate";
                        }

                      }
                      return {
                        text: a.name,
                        value: a._id,
                        rate,
                        rateDetails
                      };
                    })
                  );

                  if(assigned){
                    setData({ ...data, assigned, currentRate: assigned_rate, rateDetails, isBillable: res.billing_method == "hourly" });
                  }else{
                    setData({ ...data, rateDetails, isBillable: res.billing_method == "hourly" });
                  }

                  API.settings.get().then(res4 => setDefaultAdminRate(res4.adminRate)).catch((e) => CommonFunctions.handleError(e))

                  setMountLoading(false); 

                })
                .catch((e) => CommonFunctions.handleError(e));  

            })
          .catch((e2) => CommonFunctions.handleError(e2));

        // }).catch((e3) => CommonFunctions.handleError(e3));

        
    
      })
      
    }
  }, [mountLoading]);

  const onChange = (e) => {

    let d = data;

    if((e.target.name == "time" && e.target.value > 0) && data.isBillable !== true){
      d.isBillable = true;
    }else if((e.target.name == "time" && e.target.value == 0) && data.isBillable){
      d.isBillable = false;
    }

    setData({ ...d, [e.target.name]: e.target.value });
  };

  const onDateChange = (date) => {
    setData({
      ...data,
      due_date: date,
    });
  };

  useEffect(() => {
    let interval = null;
  
    if (timer) {
      setData((prevData) => ({
        ...prevData,
        start_time: new Date(),
      }));
  
      interval = setInterval(() => {
        setTimerClock((prevTime) => prevTime + 10);
        setData((prevData) => ({
          ...prevData,
          time: (prevData.time * 60000 + 10) / 60000, // Increment in milliseconds, convert to minutes
        }));
      }, 10);
    } else {
      setData((prevData) => ({
        ...prevData,
        end_time: new Date(),
      }));
      clearInterval(interval);
    }
  
    return () => clearInterval(interval);
  }, [timer]);
  

  return (
    <div className="">
      {matter &&
      <MatterBreadcrumbs panes={[
        { name: `Client - ${matter.contact && `${matter.contact.name} ${matter.contact.suffix ? matter.contact.suffix : ''}`}`, href: `/contacts/${matter.contact && matter.contact._id}`, current: false },
        { name: `Matter - ${matter.display_number}`, href: `/matters/${matter._id}`, current: false },
        { name: "Tasks", href: `/matters/${matter._id}/tasks`, current: false },
        { name: `Task - #${data.description}`, href: `/matters/${matter._id}/tasks/${params.task_id}`, current: false },
        { name: `Edit`, href: '#', current: true },
      ]} />}
      <Segment className="mt-4">
        <div className="">
          <h3 className="text-xl leading-6 font-medium text-gray-900">
            Run Task
          </h3>
          <div className="w-full border-t border-gray-300 my-2" />
          {mountLoading ? (
            <Loader />
          ) : (
            <>
              <Group className="py-2">
                <TextArea
                  label="Description"
                  name="description"
                  value={data.description}
                  onChange={onChange}
                />
              </Group>
              <Group className="py-2">
                <Input
                  disabled={timer}
                  type="number"
                  min="0"
                  incrementBy="6"
                  label="Time"
                  name="time"
                  value={data.time}
                  onChange={onChange}
                />
                <Button
                  className={`text-center h-10 mt-auto mb-auto mr-2 ${timer && "animate-pulse"}`}
                  colour={timer ? "sidebarTimerActive" : "sidebarTimer"}
                  // label={timerClock}
                  label={`${(
                    "0" + Math.floor((timerClock / 3600000) % 24)
                  ).slice(-2)}:${(
                    "0" + Math.floor((timerClock / 60000) % 60)
                  ).slice(-2)}:${(
                    "0" + Math.floor((timerClock / 1000) % 60)
                  ).slice(-2)}`}
                  onClick={() => {
                    setTimer(!timer);
                  }}
                  disabled={false}
                  icon={timer ? StopIcon : PlayIcon}
                />
                <Dropdown
                  label="Fee Earner"
                  name="assigned"
                  value={data.assigned}
                  onChange={(e) => {
                    const user = userList.find(
                      (item) => item.value === e.target.value
                    );
                    setData({ ...data, [e.target.name]: e.target.value, currentRate: !data.defaultAdminRate ? user.rate : data.currentRate });
                  }}
                  options={userList}
                />
                <DateSelect
                  label="Due Date / Completed Date"
                  name="due_date"
                  value={data.due_date}
                  onChange={onDateChange}
                />
                <div>

                  <Input
                    name="cost"
                    label="Cost (£) (time * (rate / 60))"
                    disabled
                    value={(
                      data.time * (data.currentRate ? data.currentRate / 60 : 0)
                    ).toFixed(2)}
                  />
                  <p>{data.rateDetails} @ £{data.currentRate} p/h</p>

                </div>
              </Group>
              <Toggle
                className="my-2"
                label="Is Billable?"
                enabled={data.isBillable}
                onChange={() =>
                  setData({
                    ...data,
                    isBillable: data.isBillable ? !data.isBillable : true,
                  })
                }
              />
              <Toggle
                className="my-2"
                label="Charge Default Admin Rate"
                enabled={data.defaultAdminRate}
                onChange={() =>
                  {
                    const user = userList.find(
                      (item) => item.value === data.assigned
                    );

                    let rate = 0;
                    let rateDetails = "";

                    if(data.defaultAdminRate){
                      rate = user.rate
                      rateDetails = user.rateDetails;
                    }else{
                      rate = defaultAdminRate;
                      rateDetails = "Default Admin Rate";
                    }

                    setData({
                      ...data,
                      defaultAdminRate: !data.defaultAdminRate,
                      currentRate: rate,
                      rateDetails,
                    });
                  }
                }
              />
              <div className="pt-2 flex justify-between">
                <Button
                  colour="alert"
                  label="Cancel"
                  onClick={() =>
                    history.push(
                      `/matters/${params.matter_id}/tasks/${params.task_id}`
                    )
                  }
                  icon={XIcon}
                />
                <Button
                  colour="positive"
                  label="Update"
                  onClick={() => {
                    API.matters.tasks
                      .update(params.matter_id, params.task_id, data)
                      .then(() =>
                        history.push(
                          `/matters/${params.matter_id}/tasks/${params.task_id}`
                        )
                      )
                      .catch((e) => CommonFunctions.handleError(e));
                  }}
                  icon={CheckIcon}
                />
              </div>
            </>
          )}
        </div>
      </Segment>
    </div>
  );
}

export default ChainedContext(withRouter(Edit), [
  [
    AuthenticationReceiver,
    (auth) => {
      return { auth };
    },
  ],
]);

