import React, { useState, useEffect } from "react";
import { Button } from "../../../components/Forms";
import dayjs from "dayjs";
import { useHistory, useParams } from "react-router-dom";
import API from "../../../api";
import Segment from "../../../components/Segment";
import CommonFunctions from "../../../CommonFunctions";
import Loader from "../../../components/Loader";
import {CheckIcon, ChevronDownIcon, ChevronUpIcon, PlusIcon } from "@heroicons/react/solid";
import MatterReceiver from "../../../contexts/matter_context/Matter_Receiver";
import ChainedContext from "../../../contexts/chainedContext";
import MatterBar from "../_components/MatterBar";
import {GiMagnifyingGlass, GiRun } from "react-icons/gi";
import MatterBreadcrumbs from "../_components/MatterBreadcrumbs";
import { Switch } from "@headlessui/react";
function TaskList({ matter }) {
  const params = useParams();
  const history = useHistory();

  const [matterTasks, setMatterTasks] = useState([]);
  const [matterDebtors, setMatterDebtors] = useState([]);
  const [mountLoading, setMountLoading] = useState(true);
  const [showAllList, setShowAllList] = useState(false);
  const [matterCollapsed, setMatterCollapsed] = useState([]);
  const [debtorTabActive, setDebtorActive] = useState(null);
  const [taskType, setTaskType] = useState("Active Tasks"); //default to list!

  useEffect(() => {

    API.matters.tasks
      .list(params.matter_id)
      .then((res) => {
        setMatterTasks(res.sort((a, b) => (a.area_task.order > b.area_task.order ? 1 : -1)));
        setMatterCollapsed(res.sort((a, b) => (a.area_task.order > b.area_task.order ? 1 : -1)).map((s) => {
          return {
            id: s._id,
            collapsed: s.complete && s.area_task.taskType !== "YesNoOption" ? s.complete : false,
          }
        }))

        API.matters.debtors
        .getAll(params.matter_id)
        .then((res2) => {
          setMatterDebtors(res2);
        })
        .catch((e2) => CommonFunctions.handleError(e2));

      })
      .catch((e) => {
        CommonFunctions.handleError(e)
      } )
      .finally(() => {
        setMountLoading(false);
      });
  }, [params.matter_id]);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  let currentTask = 0;
  let subCurrent = -1;
  let theTask = null;
  let completedTasks = 0;

  matterTasks.filter((step) => {
    let yesOption = matterTasks.filter(e => e.area_task && e.area_task.taskType == "YesNoOption" && e.area_task.YesNoOption_Yes === step.area_task._id);
    let noOption = matterTasks.filter(e => e.area_task && e.area_task.taskType == "YesNoOption" && e.area_task.YesNoOption_No === step.area_task._id);
    if(yesOption[0] || noOption[0]) {

      if(yesOption.length > 0){

        if(yesOption[0].complete_meta && yesOption[0].complete_meta.YesNoOption){
          if(yesOption[0].complete_meta.YesNoOption === "Yes"){
            return step;
          }
        }
        if(yesOption[0].complete){
          // return step;
        }
      }

      if(noOption.length > 0){

        if(noOption[0].complete_meta && noOption[0].complete_meta.YesNoOption){
          if(noOption[0].complete_meta.YesNoOption == "No"){
            return step;
          }
        }

        if(noOption[0].complete){
          // return step;
        }
      }

    }else{
      return step;
    }
  }).map((step, ind) => {
    if (step.steps.length > 0) {
      // console.log("🚀 ~ matterTasks.filter ~ currentTask:", currentTask)
      if(ind <= currentTask){

        let completedSteps = 0;
        
        step.steps.map((sub, index) => {

          if(sub.complete){
            completedTasks++;
            completedSteps++;
          }
          
          if (!sub.complete && subCurrent == -1) {
            subCurrent = index;
            theTask = sub;
          }
        });

        if(step.complete){
          completedTasks++;
        }

        if(subCurrent === -1){
          if(step.complete || (completedSteps === step.steps.length)){
            currentTask = ind + 1;
            theTask = matterTasks[ind + 1];
          }else{
            theTask = matterTasks[ind];
          }
          
        }
      } 
  
    }else if(step.complete && subCurrent === -1 && ind <= currentTask){
      currentTask = ind + 1;
      theTask = matterTasks[ind + 1];
      
    }
      
  });


  const tabs = [
    { name: 'Active Tasks' },
    { name: 'Workflow Tasks Tree' },
    // { name: 'Manual Tasks', count: matterTasks.filter(m => m.additional_task).length},
  ]

  let theTaskDue = theTask && theTask.due_date ? dayjs(theTask.due_date).diff(dayjs(), "days") : 0;

  return (
    <>
    <MatterBreadcrumbs panes={[
        { name: `Client - ${matter.contact && `${matter.contact.type == "Company" ? `${matter.contact.name} ${matter.contact.suffix ? matter.contact.suffix : ''}` : `${matter.contact.prefix} ${matter.contact.first_name} ${matter.contact.last_name} `} `}`, href: `/contacts/${matter.contact && matter.contact._id}`, current: false },
        { name: `Matter - ${matter.display_number} ${matterDebtors.debtors ? `(${matterDebtors.debtors.map((d, i) => (i !== 0 ? " " : "") + d.debtor_name)})` : ""}`, href: `/matters/${matter._id}`, current: false },
        { name: "Tasks", href: '#', current: true },
      ]} />
      {/*  
      <div className="">
        <Segment>
          <MatterBar matter={matter} history={history} />
          <div className="">
            <h3 className="leading-6 font-medium text-gray-900 flex justify-between">
              <span className="text-xl my-auto">Tasks</span>
              <Button
                colour="green"
                label="Create"
                onClick={() =>
                  history.push(`/matters/${params.matter_id}/tasks/add`)
                }
                className="ml-auto"
                icon={CheckIcon}
              />
            </h3>
            <div className="w-full border-t border-gray-300 my-2" />
          </div>
          {mountLoading ? (
            <Loader />
          ) : (
            <>
              <div className="md:grid md:grid-cols-3 md:gap-34 pt-3 pb-4">
                <div className="border border-primary p-4 rounded-xl text-center mb-4 md:mb-0 md:mx-auto md:w-52">
                  <div className="text-3xl">{matterTasks.length}</div>
                  <div className="text-xl text-primary">Tasks</div>
                </div>
                <div className="border border-primary p-4 rounded-xl text-center mb-4 md:mb-0 md:mx-auto md:w-52">
                  <div className="text-3xl">
                    {matterTasks.length > 0
                      ? matterTasks
                          .map((item) => item.time)
                          .reduce((a, b) => a + b)
                      : 0}
                  </div>
                  <div className="text-xl text-primary">Minutes</div>
                </div>
                <div className="border border-primary p-4 rounded-xl text-center md:mx-auto md:w-52">
                  <div className="text-3xl">
                    {matterTasks.length > 0
                      ? matterTasks
                          .map((item) =>
                            item.isBillable === true ? item.time : 0
                          )
                          .reduce((a, b) => a + b)
                      : 0}
                  </div>
                  <div className="text-xl text-primary">Minutes Billable</div>
                </div>
              </div>
              <ThemeTable
                header={[
                  { text: "Source", value: "source" },
                  { text: "Task", value: "description" },
                  { text: "Assigned To", value: "assigned" },
                  { text: "Due Date", value: "due_date" },
                  { text: "Status", value: "status" },
                  { text: "Time", value: "time" },
                  { text: "Billable", value: "isBillable" },
                ]}
                body={matterTasks
                  .map((item) => ({
                    ...item,
                    source:
                      item.type === "matter"
                        ? "Matter"
                        : item.type === "debtor"
                        ? `Debtor ${
                            item.debtor && item.debtor.debtor_name
                              ? `(${item.debtor.debtor_name})`
                              : ""
                          }`
                        : "",
                    isBillable: item.isBillable === true ? "Yes" : "No",
                    matter_display_number:
                      item.matter && item.matter !== null
                        ? item.matter.display_number
                        : "--",
                    assignee_name: item.assignee ? item.assignee.name : "--",
                    due_date: item.due_date
                      ? dayjs(item.due_date).format("DD/MM/YYYY")
                      : "",
                  }))
                  .sort((a, b) =>
                    a.source < b.source ? 1 : a.source > b.source ? -1 : 0
                  )}
                action={(item) =>
                  history.push(
                    item.type === "debtor"
                      ? "/matters/" +
                          params.matter_id +
                          "/debtors/" +
                          item.debtor._id +
                          "/tasks/" +
                          item._id
                      : "/matters/" + params.matter_id + "/tasks/" + item._id
                  )
                }
                noDataMessage="No Tasks..."
              />
            </>
          )}
        </Segment>
      </div>*/}
      <div className="">
        <Segment>
          <MatterBar matter={matter} history={history} />
          <div>
            <div className="">
              <div className="border-b border-gray-200">
                <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                  {tabs.map((tab, index) => (
                    <a
                      key={tab.name}
                      onClick={() => {
                        setTaskType(tab.name);
                        setDebtorActive(null);
                      }}
                      className={classNames(
                        tab.name === taskType
                          ? 'border-primary text-primary'
                          : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200',
                        'whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm cursor-pointer'
                      )}
                      aria-current={tab.name === taskType ? 'page' : undefined}
                    >
                      {tab.name}
                      {tab.count ? (
                        <span
                          className={classNames(
                            tab.name === taskType ? 'bg-primary text-white' : 'bg-gray-100 text-gray-900',
                            'hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block'
                          )}
                        >
                          {tab.count}
                        </span>
                      ) : null}
                    </a>
                  ))}
                   <Button
                    colour="positive"
                    label="Add Manual Task"
                    onClick={() => history.push(`/matters/${params.matter_id}/tasks/add/`)}
                    className="ml-auto h-8 mt-2"
                    icon={PlusIcon}
                  />
                </nav>
              </div>
            </div>
          </div>

          {mountLoading ? (
            <Loader />
          ) : (
            <>
              <div className="md:grid md:grid-cols-1 md:gap-34 pt-3 pb-4">
                <div className="border border-primary p-4 rounded-xl text-center mb-4 md:mb-0 md:mx-auto">
                  <div className="text-3xl">
                    Current Workflow Task: {/*({currentTask} {subCurrent}) */}
                  </div>
                  <div className="text-xl text-primary">
                    {theTask && theTask.description}
                  </div>
                  <div className="text-xl text-primary">
                   {theTaskDue} Days Left
                  </div>
                </div>
                {/*  
                <div className="border border-primary p-4 rounded-xl text-center mb-4 md:mb-0 md:mx-auto md:w-52">
                  <div className="text-3xl">
                    {completedTasks} / {matterTasks.length}
                  </div>
                  <div className="text-xl text-primary">
                    Tasks (Completed/Total)
                  </div>
                </div>
                <div className="border border-primary p-4 rounded-xl text-center mb-4 md:mb-0 md:mx-auto md:w-52">
                  <div className="text-3xl">
                    {matterTasks.length > 0
                      ? matterTasks
                          .map((item) => item.time)
                          .reduce((a, b) => a + b)
                      : 0}{" "}
                    /{" "}
                    {matterTasks.length > 0
                      ? matterTasks
                          .map((item) =>
                            item.isBillable === true ? item.time : 0
                          )
                          .reduce((a, b) => a + b)
                      : 0}
                  </div>
                  <div className="text-xl text-primary">
                    Minutes Recorded (Billable/Total)
                  </div>
                </div>
                  */}
              </div>

              {
              taskType === "Active Tasks" ? 
              <>
               <Switch.Group
                  as="div"
                  className="flex items-center float-right"
                >
                  <Switch
                    checked={showAllList}
                    onChange={setShowAllList}
                    className={classNames(
                      showAllList ? "bg-primary" : "bg-gray-200",
                      "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                    )}
                  >
                    <span
                      aria-hidden="true"
                      className={classNames(
                        showAllList
                          ? "translate-x-5"
                          : "translate-x-0",
                        "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                      )}
                    />
                  </Switch>
                  <Switch.Label as="span" className="ml-3">
                    <span className="text-sm font-medium text-gray-900">
                      Showing {showAllList ? "All" : "Active"} Tasks
                    </span>
                  </Switch.Label>
                </Switch.Group>
              <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-800">
                <thead>
                  <tr className="bg-gray-50 dark:bg-gray-900 text-left">
                    <th></th>
                    <th>Task</th>
                    <th>Debtor</th>
                    <th>Status</th>
                    {/* <th>Step</th> */}
                    {/* <th>No. of Steps</th> */}
                    <th>No. Days left</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody className="bg-white dark:bg-black divide-y divide-gray-200">
                {matterTasks.filter((step) => {

                  if(!step.additional_task){
                    let yesOption = matterTasks.filter(e => e.area_task && e.area_task.taskType == "YesNoOption" && e.area_task.YesNoOption_Yes === step.area_task._id);
                    let noOption = matterTasks.filter(e => e.area_task && e.area_task.taskType == "YesNoOption" && e.area_task.YesNoOption_No === step.area_task._id);
                    if(yesOption[0] || noOption[0]) {

                      if(yesOption.length > 0){

                        if(yesOption[0].complete_meta && yesOption[0].complete_meta.YesNoOption){
                          if(yesOption[0].complete_meta.YesNoOption == "Yes"){
                            return step;
                          }
                        }
                      }

                      if(noOption.length > 0){

                        if(noOption[0].complete_meta && noOption[0].complete_meta.YesNoOption){
                          if(noOption[0].complete_meta.YesNoOption == "No"){
                            return step;
                          }
                        }
                      }

                    }else{
                      return step;
                    }
                  }else{
                    return step;
                  }
                  }).map((step, stepIdx) => {
                  
                  let line_class;
                  let circle_class;
                  let filled_class;
                  if (step.complete && showAllList) {
                    line_class =
                      "absolute top-4 left-4 -ml-px mt-0.5 h-full w-0.5 bg-primary";
                    circle_class =
                      "relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-primary group-hover:bg-primary";
                  // } else if (stepIdx == currentTask) {
                  } else if (theTask && theTask._id == step._id) {
                    
                    line_class =
                      "absolute top-4 left-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300";
                    circle_class =
                      "relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-primary bg-white";
                    filled_class = "h-2.5 w-2.5 rounded-full bg-primary";
                  } else if(!showAllList && step.complete ) {
                    return;
                  }else {
                    line_class =
                      "absolute top-4 left-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300";
                    circle_class =
                      "relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white group-hover:border-gray-400";
                    filled_class =
                      "h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300";
                  }

                  let stepDaysLeft = dayjs(step.due_date).diff(dayjs(), "days");

                  return (
                    <>
                      {step.additional_task &&
                      <tr
                        key={"task_" + step._id}
                        className={classNames(
                          stepIdx !== matterTasks.length - 1 ? "pb-10" : "",
                          "relative",
                        )}
                      >
                            <td
                              className="flex h-9 items-center"
                              aria-hidden="true"
                            >
                              <span className={step.steps.length > 0 ? "" : circle_class}>
                                {step.steps.length > 0 && <>
                                  
                                  </>
                                }
                                {step.complete && (
                                  <CheckIcon
                                    className="h-5 w-5 text-white"
                                    aria-hidden="true"
                                  />
                                )}
                                {!step.steps.length > 0 &&
                                <span className={filled_class} /> }
                              </span>
                            </td>
                            <td>{step.description}</td>
                            <td>&nbsp;</td>
                            <td className={`text-sm ${step.complete ? "text-green-500" : "text-red-500"}`}>{!step.complete ? "Incomplete" : step.complete_date && ` (Completed: ${dayjs(step.complete_date).format("DD/MM/YYYY")})`}</td>
                            <td>Manual Task</td>
                            <td>
                              {!step.steps.length > 0 &&
                              <div className="flex justify-end">
                                <Button color="blue" icon={GiMagnifyingGlass}
                                className={"mr-1"}
                                  onClick={() => {
                                      history.push(
                                        "/matters/" +
                                          params.matter_id +
                                          "/tasks/" +
                                          step._id
                                      )
                                  }} 
                                />
                                <Button color="amber" icon={GiRun}
                                  onClick={() => {
                                      history.push(
                                        "/matters/" +
                                          params.matter_id +
                                          "/tasks/" +
                                          step._id + "/edit/"
                                      )
                                  }} 
                                />
                              </div>}
                            </td>
                      </tr>}
                      {step.steps.map((s, index) => {
                        
                        let step_line_class;
                        let step_circle_class;
                        let step_filled_class;

                        let debtor = matterDebtors.debtors && matterDebtors.debtors.filter(a => a._id === s.debtor);

                        if (s.complete && showAllList) {
                          step_line_class =
                            "absolute top-4 left-4 -ml-px mt-0.5 h-full w-0.5 bg-primary";
                          step_circle_class =
                            "relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-primary group-hover:bg-primary";
                        } else if (theTask && theTask._id == s._id) {
                          step_line_class =
                            "absolute top-4 left-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300";
                          step_circle_class =
                            "relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-primary bg-white";
                          step_filled_class =
                            "h-2.5 w-2.5 rounded-full bg-primary";
                        } else if(!showAllList) {
                          return;
                        }else{
                          step_line_class =
                            "absolute top-4 left-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300";
                          step_circle_class =
                            "relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-200 bg-white group-hover:border-gray-400";
                          step_filled_class =
                            "h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300";
                        }

                        let daysLeft = dayjs(s.due_date).diff(dayjs(), "days");

                        return (
                          <tr
                            onClick={() =>
                              history.push(
                                "/matters/" +
                                  params.matter_id +
                                  "/tasks/" +
                                  s._id
                              )
                            }
                            key={"step_" + s._id}
                            className={classNames(
                              "pb-10",
                              "pl-10",
                              "relative",
                              "max-w-0 w-full px-6 py-4 whitespace-nowrap text-sm text-gray-900"
                            )}
                          >
                                <td
                                  className="flex h-9 items-center"
                                  aria-hidden="true"
                                >
                                  <span className={step_circle_class}>
                                    {s.complete && (
                                      <CheckIcon
                                        className="h-5 w-5 text-white ml-2"
                                        aria-hidden="true"
                                      />
                                    )}
                                    <span className={step_filled_class} />
                                  </span>
                                </td>
                                <td>{s.description}</td>
                                <td>{debtor && debtor[0] && debtor[0].debtor_name}</td>
                                <td className={`text-sm ${s.complete ? "text-green-500" : daysLeft <= 0 ? "text-red-500" : daysLeft < 3 ? "text-orange-500" : "text-gray-500"}`}>{!s.complete ? "Incomplete" : s.complete_date && `Step Completed: ${dayjs(s.complete_date).format("DD/MM/YYYY")}`}</td>
                                <td className={`text-sm ${s.complete ? "text-green-500" : daysLeft <= 0 ? "text-red-500 animate-pulse" : daysLeft < 3 ? "text-orange-500" : "text-gray-500"}`}>{!s.complete && `${daysLeft} days left`} {s.isBillable && !s.currentRate > 0 ? "Billing Incomplete! " : ""}</td>
                                <td>
                                  <div className="flex justify-end">
                                    <Button color="blue" icon={GiMagnifyingGlass}
                                    className={"mr-1"}
                                      onClick={() => {
                                          history.push(
                                            "/matters/" +
                                              params.matter_id +
                                              "/tasks/" +
                                              s._id
                                          )
                                      }} 
                                    />
                                    <Button color="amber" icon={GiRun}
                                      onClick={() => {
                                          history.push(
                                            "/matters/" +
                                              params.matter_id +
                                              "/tasks/" +
                                              s._id + "/edit/"
                                          )
                                      }} 
                                    />
                                  </div>
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  );
                  })
                }
                </tbody>
                
              </table>
              </> : 
              taskType === "Workflow Tasks Tree" ? 
              <>
              <div className="">
                <h3 className="leading-6 font-medium text-gray-900 flex justify-between">
                  <span className="text-xl my-auto">Workflow Tasks</span>
                  <Button
                    colour="action"
                    label="View Workflow Tasks"
                    onClick={() => history.push(`/settings/area_tasks/`)}
                    className="ml-auto"
                    icon={GiMagnifyingGlass}
                  />
                </h3>
                <div className="w-full border-t border-gray-300 my-2" />
              </div>
              <div className="">
                <p>Select Debtor to view their tasks: </p>
                  <div className="grid grid-cols-1 sm:hidden">
                    <select
                      // defaultValue={matterDebtors && matterDebtors.debtors.length > 0 ? matterDebtors.debtors.find((tab) => tab._id == debtorTabActive)._id : -1}
                      aria-label="Select a tab"
                      className="col-start-1 row-start-1 w-full appearance-none rounded-md bg-white py-2 pl-3 pr-8 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600"
                    >
                      <option key="-1">- No Debtor -</option>
                      {matterDebtors && matterDebtors.debtors.map((tab) => (
                        <option key={tab.debtor_name}>{tab.debtor_name}</option>
                      ))}
                    </select>
                    <ChevronDownIcon
                      aria-hidden="true"
                      className="pointer-events-none col-start-1 row-start-1 mr-2 size-5 self-center justify-self-end fill-gray-500"
                    />
                  </div>
                  <div className="hidden sm:block">
                    <div className="border-b border-gray-200">
                      <nav aria-label="Tabs" className="-mb-px flex space-x-8">
                        <a
                            key={-1}
                            onClick={() => setDebtorActive(-1)}
                            // href={tab.href} ID to change
                            aria-current={debtorTabActive == -1 ? 'page' : undefined}
                            className={classNames(
                              debtorTabActive == -1 ? 'border-primary text-primary' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                              'whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium cursor-pointer',
                            )}
                          >
                            - No Debtor -
                          </a>
                        {matterDebtors && matterDebtors.debtors.map((tab) => {
                          console.log("🚀 ~ {matterDebtors&&matterDebtors.debtors.map ~ tab:", tab)
                          return(
                          <a
                            key={tab._id}
                            onClick={() => setDebtorActive(tab._id)}
                            // href={tab.href} ID to change
                            aria-current={debtorTabActive == tab._id ? 'page' : undefined}
                            className={classNames(
                              debtorTabActive == tab._id ? 'border-primary text-primary' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                              'whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium cursor-pointer',
                            )}
                          >
                            {tab.debtor_name}
                          </a>
                        )})}
                      </nav>
                    </div>
                  </div>
              </div>
              <nav aria-label="Progress">
                <ol role="list" className="overflow-hidden">
                  {matterTasks.filter(s => {
                    if (debtorTabActive === -1 && !s.debtor) {
                      return true;
                    }
                    return s.debtor && s.debtor._id === debtorTabActive;
                  }).filter((step) => {

                    if(!step.additional_task){
                      let yesOption = matterTasks.filter(e => e.area_task && e.area_task.taskType == "YesNoOption" && e.area_task.YesNoOption_Yes === step.area_task._id);
                      let noOption = matterTasks.filter(e => e.area_task && e.area_task.taskType == "YesNoOption" && e.area_task.YesNoOption_No === step.area_task._id);
                      if(yesOption[0] || noOption[0]) {
  
                        if(yesOption.length > 0){
  
                          if(yesOption[0].complete_meta && yesOption[0].complete_meta.YesNoOption){
                            if(yesOption[0].complete_meta.YesNoOption == "Yes"){
                              return step;
                            }
                          }
                        }
  
                        if(noOption.length > 0){
  
                          if(noOption[0].complete_meta && noOption[0].complete_meta.YesNoOption){
                            if(noOption[0].complete_meta.YesNoOption == "No"){
                              return step;
                            }
                          }
                        }
  
                      }else{
                        return step;
                      }
                    }
                  }).map((step, stepIdx) => {
                    /*
                    let yesOption = matterTasks.filter(e => e.area_task && e.area_task.taskType == "YesNoOption" && e.area_task.YesNoOption_Yes === step.area_task._id);
                    let noOption = matterTasks.filter(e => e.area_task && e.area_task.taskType == "YesNoOption" && e.area_task.YesNoOption_No === step.area_task._id);

                    if(yesOption.length > 0){

                      if(yesOption[0].complete_meta && yesOption[0].complete_meta.YesNoOption){
                        if(yesOption[0].complete_meta.YesNoOption !== "Yes"){
                          // return;
                        }
                      }
                      if(!yesOption[0].complete){
                        // return;
                      }
                    }

                    if(noOption.length > 0){

                      if(noOption[0].complete_meta && noOption[0].complete_meta.YesNoOption){
                        if(noOption[0].complete_meta.YesNoOption !== "No"){
                          // return;
                        }
                      }

                      if(!noOption[0].complete){
                        // return;
                      }
                    } */
                    let line_class;
                    let circle_class;
                    let filled_class;

                    let ynline_class;
                    let yncircle_class;
                    let ynfilled_class;

                    let collapse_class = "relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-blue-500";
                    let yncollapse_class = "relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-blue-500";
                    let matterCollapse = matterCollapsed.filter(c => c.id === step._id);
                    let hidden_steps = matterCollapse && matterCollapse[0] ? matterCollapse[0].collapsed : false;
                    let collapsed_step = matterCollapsed.filter(e => e.id == step._id);
                    if (step.complete) {
                      line_class =
                        "absolute top-4 left-4 -ml-px mt-0.5 h-full w-0.5 bg-primary";
                      circle_class =
                        "relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-primary group-hover:bg-primary";
                      collapse_class = "relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-primary"; 

                      ynline_class =
                      "absolute top-4 left-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300";
                      yncircle_class =
                        "relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-primary bg-white";
                      ynfilled_class = "h-2.5 w-2.5 rounded-full bg-primary";

                    // } else if (stepIdx == currentTask) {
                    } else if (theTask && theTask._id == step._id) {
                      
                      line_class =
                        "absolute top-4 left-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300";
                      circle_class =
                        "relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-primary bg-white";
                      filled_class = "h-2.5 w-2.5 rounded-full bg-primary";

                      ynline_class = line_class;
                      yncircle_class = circle_class;
                      ynfilled_class = filled_class;

                    } else {
                      line_class =
                        "absolute top-4 left-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300";
                      circle_class =
                        "relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white group-hover:border-gray-400";
                      filled_class =
                        "h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300";

                      ynline_class = line_class;
                      yncircle_class = circle_class;
                      ynfilled_class = filled_class;
                    }

                    let stepDaysLeft = dayjs(step.due_date).diff(dayjs(), "days");

                    return (
                      <>
                      {(step.area_task.taskType == "YesNoOption" || step.steps.length > 0) &&
                        <li
                          onClick={() =>
                            {
                              if(step.steps.length > 0 || step.area_task.taskType == "YesNoOption"){
                                setMatterCollapsed(matterCollapsed.map((c) => {
                                  if(c.id === step._id){
                                    c.collapsed = !c.collapsed;
                                  }
                                  return c;
                                }));
                              }else{
                                history.push(
                                  "/matters/" +
                                    params.matter_id +
                                    "/tasks/" +
                                    step._id
                                )
                              }
                              
                            }
                          } 
                          key={"task_" + step._id}
                          className={classNames(
                            stepIdx !== matterTasks.length - 1 ? "pb-10" : "",
                            "relative",
                            "hover:opacity-80",
                            "cursor-pointer",
                          )}
                        >
                          <>
                            {stepIdx !== matterTasks.length - 1 ? (
                              <div className={step.area_task.taskType == "YesNoOption" ? ynline_class : line_class} aria-hidden="true" />
                            ) : null}
                            <a
                              href={step.href}
                              className="group relative flex items-start"
                            >
                              <span
                                className="flex h-9 items-center"
                                aria-hidden="true"
                              >
                                <span className={(step.steps.length > 0 || step.area_task.taskType == "YesNoOption") ?   step.area_task.taskType == "YesNoOption" ? yncollapse_class : collapse_class  : circle_class}>
                                  {(step.steps.length > 0 || step.area_task.taskType == "YesNoOption") && <>
                                    {collapsed_step ? collapsed_step[0].collapsed ? 
                                      <ChevronDownIcon className="h-8 w-8 text-white"
                                        aria-hidden="true" /> : <ChevronUpIcon className="h-8 w-8 text-white"
                                        aria-hidden="true" /> : ""}
                                    
                                    </>
                                  }
                                  {step.complete && step.area_task.taskType != "YesNoOption" && (
                                    <CheckIcon
                                      className="h-5 w-5 text-white"
                                      aria-hidden="true"
                                    />
                                  )}
                                  {(!step.steps.length > 0 && step.area_task.taskType != "YesNoOption") &&
                                  <span className={ step.area_task.taskType == "YesNoOption" ? ynfilled_class: filled_class} /> }
                                </span>
                              </span>
                              <span className="ml-4 flex min-w-0 flex-col">
                                <span className="text-sm font-medium text-gray-500">
                                  {step.description} {step.complete_date && ` (Completed: ${dayjs(step.complete_date).format("DD/MM/YYYY")})`} -
                                </span>
                                <span className="text-sm text-gray-500">
                                  {step.steps.length > 0 ? `${step.steps.length} Step(s), click to view/collapse all Steps` : 
                                    step.area_task.taskType == "YesNoOption" ? "Yes/No Step, click to view/collapse Step" :
                                    !step.complete && `${stepDaysLeft} days left`
                                  }
                                </span>
                                <ol
                                  role="list"
                                  className="overflow-hidden"
                                ></ol>
                              </span>
                            </a>
                          </>
                        </li>}
                        {step.area_task.taskType == "YesNoOption" ? 
                        
                        <li
                              onClick={() =>
                                history.push(
                                  "/matters/" +
                                    params.matter_id +
                                    "/tasks/" +
                                    step._id
                                )
                              }
                              key={"step_yes_no_" + step._id}
                              className={classNames(
                                "pb-10",
                                "pl-10",
                                "relative",
                                "hover:opacity-80",
                                "cursor-pointer",
                                hidden_steps ? "hidden" : ""
                              )}
                            >
                              <>
                                <div
                                  className={ynline_class}
                                  aria-hidden="true"
                                />
                                <a
                                  href={step.href}
                                  className="group relative flex items-start"
                                >
                                  <span
                                    className="flex h-9 items-center"
                                    aria-hidden="true"
                                  >
                                    <span className={circle_class}>
                                      {step.complete && (
                                        <CheckIcon
                                          className="h-5 w-5 text-white ml-2"
                                          aria-hidden="true"
                                        />
                                      )}
                                      <span className={filled_class} />
                                    </span>
                                  </span>
                                  <span className="ml-4 flex min-w-0 flex-col">
                                    <span className="text-sm font-medium text-gray-500">
                                      {step.description}
                                    </span>
                                    <span className={`text-sm ${step.complete ? "text-green-500" : stepDaysLeft <= 0 ? "text-red-500 animate-pulse" : stepDaysLeft < 3 ? "text-orange-500" : "text-gray-500"}`}>
                                      {step.complete_date && `Step Completed: ${dayjs(step.complete_date).format("DD/MM/YYYY")}`} {step.isBillable && !step.currentRate > 0 ? "Billing Incomplete! " : ""}
                                      {!step.complete && `${stepDaysLeft} days left`}
                                    </span>
                                  </span>
                                </a>
                              </>
                            </li>
                        
                        : step.steps.map((s, index) => {
                          let step_line_class;
                          let step_circle_class;
                          let step_filled_class;

                          if (s.complete) {
                            step_line_class =
                              "absolute top-4 left-4 -ml-px mt-0.5 h-full w-0.5 bg-primary";
                            step_circle_class =
                              "relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-primary group-hover:bg-primary";
                          } else if (theTask && theTask._id == s._id) {
                            step_line_class =
                              "absolute top-4 left-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300";
                            step_circle_class =
                              "relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-primary bg-white";
                            step_filled_class =
                              "h-2.5 w-2.5 rounded-full bg-primary";
                          } else {
                            step_line_class =
                              "absolute top-4 left-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300";
                            step_circle_class =
                              "relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white group-hover:border-gray-400";
                            step_filled_class =
                              "h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300";
                          }

                          let daysLeft = dayjs(s.due_date).diff(dayjs(), "days");

                          return (
                            <li
                              onClick={() =>
                                history.push(
                                  "/matters/" +
                                    params.matter_id +
                                    "/tasks/" +
                                    s._id
                                )
                              }
                              key={"step_" + s._id}
                              className={classNames(
                                "pb-10",
                                "pl-10",
                                "relative",
                                "hover:opacity-80",
                                "cursor-pointer",
                                hidden_steps ? "hidden" : ""
                              )}
                            >
                              <>
                                <div
                                  className={step_line_class}
                                  aria-hidden="true"
                                />
                                {index !== step.steps.length - 1 && ( 
                                  <div
                                    className={classNames(step_line_class, "ml-10")}
                                    aria-hidden="true"
                                  />
                                 )}
                                <a
                                  href={s.href}
                                  className="group relative flex items-start"
                                >
                                  <span
                                    className="flex h-9 items-center"
                                    aria-hidden="true"
                                  >
                                    <span className={step_circle_class}>
                                      {s.complete && (
                                        <CheckIcon
                                          className="h-5 w-5 text-white ml-2"
                                          aria-hidden="true"
                                        />
                                      )}
                                      <span className={step_filled_class} />
                                    </span>
                                  </span>
                                  <span className="ml-4 flex min-w-0 flex-col">
                                    <span className="text-sm font-medium text-gray-500">
                                      {s.description}
                                    </span>
                                    <span className={`text-sm ${s.complete ? "text-green-500" : daysLeft <= 0 ? "text-red-500 animate-pulse" : daysLeft < 3 ? "text-orange-500" : "text-gray-500"}`}>
                                      {s.complete_date && `Step Completed: ${dayjs(s.complete_date).format("DD/MM/YYYY")}`} {s.isBillable && !s.currentRate > 0 ? "Billing Incomplete! " : ""}
                                      {!s.prevComplete && `Previous Task not Completed Yet!`}
                                      {!s.complete && s.prevComplete && `${daysLeft} days left`}
                                    </span>
                                  </span>
                                </a>
                              </>
                            </li>
                          );
                        })}
                        {/*step.steps.length > 0 && <li
                          onClick={() =>
                            {
                              history.push(
                                "/matters/" +
                                  params.matter_id +
                                  "/tasks/" +
                                  step._id
                              ) 
                            }
                          } 
                          key={"task_main_" + step._id}
                          className={classNames(
                            "pb-10",
                            "pl-10",
                            "relative",
                            "hover:opacity-80",
                            "cursor-pointer",
                            hidden_steps ? "hidden" : ""
                          )}
                        >
                          <>
                            <div
                              className={line_class}
                              aria-hidden="true"
                            />
                            <a
                              href={step.href}
                              className="group relative flex items-start"
                            >
                              <span
                                className="flex h-9 items-center"
                                aria-hidden="true"
                              >
                                <span className={circle_class}>
                                  {step.complete && (
                                    <CheckIcon
                                      className="h-5 w-5 text-white ml-2"
                                      aria-hidden="true"
                                    />
                                  )}
                                  <span className={filled_class} />
                                </span>
                              </span>
                              <span className="ml-4 flex min-w-0 flex-col">
                                <span className="text-sm font-medium text-gray-500">
                                  {step.description} {step.complete_date && ` (Completed: ${dayjs(step.complete_date).format("DD/MM/YYYY")})`} ??
                                </span>
                                <span className={`text-sm ${step.complete ? "text-green-500" : stepDaysLeft <= 0 ? "text-red-500 animate-pulse" : stepDaysLeft < 3 ? "text-orange-500" : "text-gray-500"}`}>
                                  {step.complete_date && `Step Completed: ${dayjs(step.complete_date).format("DD/MM/YYYY")}`}
                                  {!step.complete && `${stepDaysLeft} days left`}
                                </span>
                              </span>
                            </a>
                          </>
                        </li>*/}
                      </>
                    );
                  })}
                </ol>
              </nav>
              </> : 
              <>
              <div className="">
                <h3 className="leading-6 font-medium text-gray-900 flex justify-between">
                  <span className="text-xl my-auto">Manual Tasks</span>
                  {/*  
                  <Button
                    colour="green"
                    label="Add Manual Task"
                    onClick={() => history.push(`/matters/${params.matter_id}/tasks/add/`)}
                    className="ml-auto"
                    icon={PlusIcon}
                  />*/}
                </h3>
                <div className="w-full border-t border-gray-300 my-2" />
              </div>
              <nav aria-label="Progress">
                <ol role="list" className="overflow-hidden">
                  {matterTasks.filter(m => m.additional_task).map((step, stepIdx) => {
                    let circle_class;
                    let filled_class;

                    if (step.complete) {
                      circle_class =
                        "relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-primary group-hover:bg-primary";
                    // } else if (stepIdx == currentTask) {
                    } else if (theTask && theTask._id == step._id) {
                      circle_class =
                        "relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-primary bg-white";
                      filled_class = "h-2.5 w-2.5 rounded-full bg-primary";
                    } else {
                      circle_class =
                        "relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white group-hover:border-gray-400";
                      filled_class =
                        "h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300";
                    }

                    return (
                      <>
                        <li
                          onClick={() =>
                            {
                              history.push(
                                "/matters/" +
                                  params.matter_id +
                                  "/tasks/" +
                                  step._id
                              )
                            }
                          } 
                          key={"task_" + step._id}
                          className={classNames(
                            stepIdx !== matterTasks.length - 1 ? "pb-10" : "",
                            "relative",
                            "hover:opacity-80",
                            "cursor-pointer",
                          )}
                        >
                          <>
                            <a
                              href={step.href}
                              className="group relative flex items-start"
                            >
                              <span
                                className="flex h-9 items-center"
                                aria-hidden="true"
                              >
                                <span className={circle_class}>
                                  {step.complete && (
                                    <CheckIcon
                                      className="h-5 w-5 text-white"
                                      aria-hidden="true"
                                    />
                                  )}
                                  <span className={filled_class} />
                                </span>
                              </span>
                              <span className="ml-4 flex min-w-0 flex-col">
                                <span className="text-sm font-medium text-gray-500">
                                  {step.description} {step.complete_date && ` (Completed: ${dayjs(step.complete_date).format("DD/MM/YYYY")})`}
                                </span>
                                <span className="text-sm text-gray-500">
                                  due within ${step.dueDays} days from comepletion of previous
                                </span>
                                <ol
                                  role="list"
                                  className="overflow-hidden"
                                ></ol>
                              </span>
                            </a>
                          </>
                        </li>
                      </>
                    );
                  })}
                </ol>
              </nav>
              </>
              }    
            </>
          )}
        </Segment>
      </div>
    </>
  );
}
export default ChainedContext(TaskList, [
  [
    MatterReceiver,
    (matter) => {
      return { matter };
    },
  ],
]);
